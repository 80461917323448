// class StickyHeader {
// 	constructor() {
// 		this.stickyHeader = document.querySelector('.sticky-header');
// 		this.init();
// 	}

// 	getHeight() {
// 		return this.stickyHeader ? this.stickyHeader.offsetHeight : 0;
// 	}

// 	adjustScrollForHash(hash) {
// 		const targetId = hash.startsWith('#') ? hash.slice(1) : hash;
// 		const targetElement = document.getElementById(targetId);

// 		if (targetElement) {
// 			const stickyHeight = this.getHeight();
// 			const targetPosition =
// 				targetElement.getBoundingClientRect().top +
// 				window.pageYOffset -
// 				stickyHeight;

// 			window.scrollTo({
// 				top: targetPosition,
// 				behavior: 'smooth',
// 			});
// 		}
// 	}

// 	handleAnchorClicks() {
// 		document.addEventListener('click', (e) => {
// 			let anchor = e.target;

// 			while (anchor && anchor.tagName !== 'A') {
// 				anchor = anchor.parentElement;
// 			}

// 			if (
// 				anchor &&
// 				anchor.hash &&
// 				!anchor.target &&
// 				!e.metaKey &&
// 				!e.ctrlKey &&
// 				!e.shiftKey &&
// 				!e.altKey
// 			) {
// 				e.preventDefault();
// 				this.adjustScrollForHash(anchor.hash);
// 			}
// 		});
// 	}

// 	init() {
// 		window.addEventListener('load', () => {
// 			if (window.location.hash) {
// 				this.adjustScrollForHash(window.location.hash);
// 			}
// 		});

// 		this.handleAnchorClicks();
// 	}
// }

// export default StickyHeader;

class StickyHeader {
	constructor() {
		this.stickyHeader = document.querySelector('.sticky-header');
		this.init();
	}

	getHeight() {
		return this.stickyHeader ? this.stickyHeader.offsetHeight : 0;
	}

	adjustScrollForHash(hash) {
		const targetId = hash.startsWith('#') ? hash.slice(1) : hash;
		const targetElement = document.getElementById(targetId);

		if (targetElement) {
			const stickyHeight = this.getHeight();
			const targetPosition =
				targetElement.getBoundingClientRect().top +
				window.pageYOffset -
				stickyHeight;

			window.scrollTo({
				top: targetPosition,
				behavior: 'smooth',
			});
		}
	}

	handleAnchorClicks() {
		document.addEventListener('click', (e) => {
			let anchor = e.target;

			while (anchor && anchor.tagName !== 'A') {
				anchor = anchor.parentElement;
			}

			if (
				anchor &&
				anchor.hash &&
				!anchor.target &&
				!e.metaKey &&
				!e.ctrlKey &&
				!e.shiftKey &&
				!e.altKey
			) {
				// Detect navigation to the same page
				if (
					anchor.origin === window.location.origin &&
					anchor.pathname === window.location.pathname
				) {
					e.preventDefault();
					this.adjustScrollForHash(anchor.hash);
				}
			}
		});
	}

	init() {
		// Handle hash scrolling on page load
		window.addEventListener('load', () => {
			if (window.location.hash) {
				this.adjustScrollForHash(window.location.hash);
			}
		});

		// Add smooth scroll for anchor clicks
		this.handleAnchorClicks();
	}
}

export default StickyHeader;
