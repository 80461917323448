class FormLoaderManager {
	constructor(loaderId = 'loader') {
		if (!FormLoaderManager.instance) {
			this.loader = document.getElementById(loaderId);
			if (!this.loader) {
				throw new Error(`Loader element with id "${loaderId}" not found.`);
			}
			this.timeoutId = null;
			this.messageElement = null;
			FormLoaderManager.instance = this;
		}
		return FormLoaderManager.instance;
	}

	show() {
		this.loader.style.display = 'flex';
		// Start the timeout to show the message after 5 seconds
		this.timeoutId = setTimeout(() => {
			this.showMessage();
		}, 5000);
	}

	hide() {
		this.loader.style.display = 'none';
		// Clear the timeout when the loader is hidden
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}
		// Hide the message if it's visible
		if (this.messageElement) {
			this.messageElement.style.display = 'none';
		}
	}

	showMessage() {
		if (!this.messageElement) {
			this.messageElement = document.createElement('div');
			this.messageElement.style.position = 'absolute';
			this.messageElement.style.top = '50%';
			this.messageElement.style.left = '50%';
			this.messageElement.style.transform = 'translate(-50%, 110%)';
			this.messageElement.style.color = 'white';
			this.messageElement.style.padding = '10px';
			this.messageElement.style.borderRadius = '5px';
			this.messageElement.style.zIndex = '1000';
			this.messageElement.innerText =
				"Please wait, we're uploading your files...";
			this.loader.appendChild(this.messageElement);
		}
		this.messageElement.style.display = 'block';
	}
}

export default FormLoaderManager;
