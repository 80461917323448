import JobDataService from './JobDataService';
import JobFormHandler from './JobFormHandler';
import JobStepManager from './JobStepManager';
import JobUIManager from './JobUIManager';

class JobApplication {
	constructor() {
		this.formId = 'applicationForm';
		this.siteUrl = siteData.siteUrl;
		this.jobSlug = this.getJobSlugFromUrl();
		this.uiManager = new JobUIManager();
		this.dataService = new JobDataService(this.siteUrl, this.jobSlug);

		// Initialise Steps
		this.step2 = document.querySelector('.job-single__app-step--2');
		this.step3 = document.querySelector('.job-single__app-step--3');
		this.step4 = document.querySelector('.job-single__app-step--4');
		this.steps = [this.step2, this.step3, this.step4];

		if (this.step2 && this.step3 && this.step4) {
			// Initialise Form Handler
			this.formHandler = new JobFormHandler(
				this.formId,
				this.dataService,
				this.uiManager,
				null // Placeholder for stepManager, will set below
			);

			// Initialise Step Manager
			this.stepManager = new JobStepManager(
				this.steps,
				this.uiManager,
				this.formHandler
			);

			// Update Form Handler with reference to Step Manager
			this.formHandler.stepManager = this.stepManager;
		}
	}

	getJobSlugFromUrl() {
		const urlParts = window.location.pathname.split('/');
		return urlParts[urlParts.length - 2];
	}
}

export default JobApplication;
