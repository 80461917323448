import StickyHeader from './StickyHeader';

class JobUIManager {
	constructor() {
		this.loader = document.getElementById('loader');
		this.stickyHeader = new StickyHeader();
	}

	showLoader() {
		if (this.loader) {
			this.loader.style.display = 'flex';
		}
	}

	hideLoader() {
		if (this.loader) {
			this.loader.style.display = 'none';
		}
	}

	scrollToElement(element, stepNumber) {
		const offset = this.stickyHeader.getHeight();
		let position =
			element.getBoundingClientRect().top + window.pageYOffset - offset;

		if (stepNumber === 3) {
			// Scroll to top for step 3
			position = 0;
		}

		window.scrollTo({ top: position, behavior: 'smooth' });
	}
}

export default JobUIManager;
