class CustomUpload {
	constructor(formElement) {
		this.formElement = formElement;
		this.labelSelector = '.custom-upload__label';
		this.maxLength = 35;
		this.maxFileSize = 20 * 1024 * 1024; // 20 MB in bytes

		if (this.formElement) {
			this.init();
		}
	}

	init() {
		this.removeBreaks(this.formElement);

		this.inputElement = this.formElement.querySelector('input[type="file"]');
		this.labelElement = this.formElement.querySelector(this.labelSelector);

		this.inputElement.addEventListener('change', () => this.handleFileChange());
	}

	removeBreaks(element) {
		const brTags = element.querySelectorAll('br');
		brTags.forEach((br) => br.remove());
	}

	handleFileChange() {
		if (this.inputElement.files.length > 0) {
			const file = this.inputElement.files[0];

			// Validate file size
			if (file.size > this.maxFileSize) {
				alert(
					`The selected file "${file.name}" exceeds the 20 MB size limit. Please choose a smaller file.`
				);
				this.clearInput();
				return; // Prevent further processing of the file
			}

			// Format and display file name
			const formattedName = this.formatFileName(file.name);
			this.labelElement.textContent = `${formattedName}`;
		} else {
			this.resetLabel();
		}
	}

	formatFileName(fileName) {
		if (fileName.length <= this.maxLength) {
			return fileName; // Return the original name if it's short enough.
		}

		const half = Math.floor((this.maxLength - 3) / 2);
		const start = fileName.slice(0, half); // Get the starting part.
		const end = fileName.slice(-half); // Get the ending part with the extension.

		return `${start}...${end}`; // Combine with ellipses in the middle.
	}

	clearInput() {
		this.inputElement.value = ''; // Clear the input field
		this.resetLabel();
	}

	resetLabel() {
		this.labelElement.textContent = 'Upload a document'; // Reset label to default
	}
}

export default CustomUpload;
