import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import Header from './components/Header';
import ClientLogos from './components/ClientLogos';
import Testimonials from './components/Testimonials';
import ClientCandidateServices from './components/ClientCandidateServices';
import ZenonDifferenceCarousel from './components/ZenonDifferenceCarousel';
import ServicesWeOffer from './components/ServicesWeOffer';
import ProviderAccordion from './components/ProviderAccordion';
import JobApplication from './components/JobApplication';
import SaveJob from './components/SaveJob';
import MenuUnderline from './components/MenuUnderline';
import CustomUpload from './components/CustomUpload';
import FormHandler from './components/FormHandler';
import GdprHandler from './components/GdprHandler';
import FacetWPHandler from './components/FacetWPHandler';
import StickyHeader from './components/StickyHeader';

for (const header of document.querySelectorAll('.site-header')) {
	new Header(header);
}

document.querySelectorAll('.pelling-client-logos').forEach((block) => {
	new ClientLogos(block);
});

document.querySelectorAll('.pelling-cc-services').forEach((block) => {
	new ClientCandidateServices(block);
});

document
	.querySelectorAll('.pelling-zenon-difference-carousel')
	.forEach((block) => {
		new ZenonDifferenceCarousel(block);
	});

document.querySelectorAll('.pelling-services-we-offer').forEach((block) => {
	new ServicesWeOffer(block);
});

document.querySelectorAll('.provider-accordion').forEach((accordion) => {
	new ProviderAccordion(accordion);
});

document.querySelectorAll('.pelling-testimonials').forEach((block) => {
	new Testimonials(block);
});

document.addEventListener('DOMContentLoaded', () => {
	document
		.querySelectorAll('.custom-upload__container')
		.forEach((formElement) => {
			new CustomUpload(formElement);
		});
});

new FacetWPHandler();

document.addEventListener('DOMContentLoaded', () => {
	new JobApplication();
	new SaveJob();
	new MenuUnderline();

	new FormHandler({
		formId: 'vacancyForm',
		modalId: 'registerVacancyModal',
		successMessage: 'Your vacancy has been registered successfully!',
		action: 'register_vacancy',
	});

	new FormHandler({
		formId: 'registerForm',
		modalId: 'registerJobSeekerModal',
		successMessage: 'You have successfully registered as a job seeker!',
		action: 'register_job_seeker',
	});

	new FormHandler({
		formId: 'cvForm',
		modalId: 'submitCvModal',
		successMessage: 'Your CV has been sent successfully!',
		action: 'submit_cv',
	});

	new GdprHandler('cvForm', 'submitCvModal');
	new GdprHandler('registerForm', 'registerJobSeekerModal');

	/////////////////////////
	// miscellaneous
	////////////////////////

	// Add classes to service single description lists
	const descriptionContainers = document.querySelectorAll(
		'.service-single__description'
	);

	descriptionContainers.forEach((container) => {
		const uls = container.querySelectorAll('ul');

		uls.forEach((ul) => {
			const listItems = ul.querySelectorAll('li');
			if (listItems.length > 8) {
				ul.classList.add('two-columns');
			}
		});
	});

	// Add accept attribute to multi file inputs.
	const fileInputs = document.querySelectorAll(
		'.custom-upload__container--multi input[type="file"]'
	);
	const fileButtons = document.querySelectorAll(
		'.custom-upload__container--multi input[type="button"]'
	);

	// for (const fileInput of fileInputs) {
	// 	// Set the allowed file types
	// 	fileInput.setAttribute('accept', '.pdf,.doc,.docx,jpeg,.jpg,.png,.gif');
	// }

	for (const fileButton of fileButtons) {
		fileButton.classList.add('btn-pelling', 'btn-pelling--z-blue', 'outline');
	}
});

// Add attibues to ACF block buttons for modals
document.querySelectorAll('#addCvModal').forEach((button) => {
	button.setAttribute('data-bs-toggle', 'modal');
	button.setAttribute('data-bs-target', '#submitCvModal');
});

document.querySelectorAll('#addJobModal').forEach((button) => {
	button.setAttribute('data-bs-toggle', 'modal');
	button.setAttribute('data-bs-target', '#registerVacancyModal');
});
