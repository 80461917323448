class JobDataService {
	constructor(siteUrl, jobSlug) {
		this.siteUrl = siteUrl;
		this.jobSlug = jobSlug;
	}

	async fetchJobDetails() {
		const response = await fetch(
			`${this.siteUrl}/wp-json/wp/v2/job?slug=${this.jobSlug}`
		);
		if (!response.ok) {
			throw new Error('Failed to fetch job details');
		}
		const jobDataArray = await response.json();
		const jobData = jobDataArray[0];

		const consultantData = await this.fetchConsultantDetails(
			jobData.consultant[0]
		);

		return { jobData, consultantData };
	}

	async fetchConsultantDetails(consultantId) {
		const response = await fetch(
			`${this.siteUrl}/wp-json/wp/v2/consultant/${consultantId}`
		);
		if (!response.ok) {
			throw new Error('Failed to fetch consultant details');
		}
		return response.json();
	}
}

export default JobDataService;
