import FormFileManager from './FormFileManager.js';
import FormValidator from './FormValidator.js';
import FormModalManager from './FormModalManager.js';
import FormLoaderManager from './FormLoaderManager.js';

class FormHandler {
	constructor(config) {
		this.form = document.getElementById(config.formId);
		this.fileManager = new FormFileManager(config.formId);
		this.validator = new FormValidator();
		this.modalManager = new FormModalManager(config.modalId, 'successModal');
		this.loaderManager = new FormLoaderManager();
		this.successMessage = config.successMessage;
		this.action = config.action;

		this.init();
	}

	init() {
		this.form.addEventListener('submit', (e) => this.handleSubmit(e));
	}

	async handleSubmit(event) {
		event.preventDefault();

		const validationError = this.validator.validate(
			this.form,
			this.fileManager
		);
		if (validationError) {
			alert(validationError);
			return;
		}

		const formData = new FormData(this.form);

		// Append single upload file
		if (
			this.fileManager.singleFileInput &&
			this.fileManager.singleFileInput.files.length > 0
		) {
			formData.append('jobFile', this.fileManager.singleFileInput.files[0]);
		}

		// Append multi-upload files
		this.fileManager.selectedFiles.forEach((file) =>
			formData.append('jobFiles[]', file)
		);

		// Close the current modal
		this.modalManager.close();

		this.loaderManager.show();

		try {
			const response = await fetch(
				`/wp-admin/admin-ajax.php?action=${this.action}`,
				{
					method: 'POST',
					body: formData,
				}
			);

			const result = await response.json();
			this.loaderManager.hide();

			if (result.success) {
				// Reset the form
				this.form.reset();

				// Reset the file manager for both single and multi-upload fields
				this.resetFileManager();

				// Show the success modal
				this.modalManager.showSuccess(this.successMessage);
			} else {
				this.modalManager.showSuccess(
					result.data?.message || 'Submission failed. Please try again.',
					false
				);
			}
		} catch (error) {
			this.loaderManager.hide();
			this.modalManager.showSuccess(
				'An error occurred. Please try again.',
				false
			);
		}
	}

	resetFileManager() {
		// Clear the single file input
		if (this.fileManager.singleFileInput) {
			this.fileManager.singleFileInput.value = '';
		}

		// Clear the multi-upload selected files
		this.fileManager.selectedFiles = [];
		this.fileManager.updateSelectedFilesDisplay();
		this.fileManager.updateTotalFileSizeDisplay();

		// Ensure the "Upload More" button is enabled
		this.fileManager.updateAddFileButtonState();

		const customUploadLabels = document.querySelectorAll(
			'.custom-upload__label'
		);
		customUploadLabels.forEach((label) => {
			label.textContent = 'Upload a document (optional)';
		});
	}
}

export default FormHandler;
