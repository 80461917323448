class FormValidator {
	validate(form, fileManager) {
		const requiredFields = form.querySelectorAll('[required]');
		for (const field of requiredFields) {
			if (!field.value.trim()) {
				return 'Please fill out all required fields.';
			}
		}

		// Check total file size
		if (fileManager.calculateTotalFileSize() > fileManager.maxFileSize) {
			return 'The total file size exceeds the limit of 12 MB.';
		}

		return null;
	}
}

export default FormValidator;
